import { Component, OnInit, Input } from '@angular/core';
import { DataService } from '../../services/data.service';
import { Router } from '@angular/router';
import { ModalController, AlertController, LoadingController } from '@ionic/angular';
import { App } from '@capacitor/app';
@Component({
  selector: 'app-language-settings',
  templateUrl: './language-settings.component.html',
  styleUrls: ['./language-settings.component.scss'],
})
export class LanguageSettingsComponent implements OnInit {
  @Input() value: any;
  @Input() id: any;

  mobileUiText;
  languageDefault;
  languageDropdown;
  countryDropdown;
  countryDefault;
  networkStatus;
  deviceLangCode;
  countryCode;
  productId;
  backbtn;
  loading = this.loadingController.create({
    cssClass: 'my-custom-class',
    message: 'Please wait...',
    duration: 5000,
  });
  constructor(private readonly router: Router, public modalController: ModalController,
    public dataService: DataService, public alertController: AlertController
    , public loadingController: LoadingController) {
    this.backbtn = App.addListener('backButton', data => {
      this.GoBack();
    })

  }
  ionViewDidLeave() {

    this.backbtn.remove();
  }

  ngOnInit() {
    this.dataService.getObject('defaultLangCode').then((defLangCode: any) => {
      this.dataService.deviceLangCode = defLangCode.value;
      this.deviceLangCode = this.dataService.deviceLangCode;

      this.dataService.getObject('defaultLanguage').then((defLang: any) => {
        this.languageDefault = defLang.value;
        this.dataService.defaultLanguage = this.languageDefault;

      })
      this.dataService.getObject('defaultCountry').then(defaultCountry => {
        this.countryDefault = defaultCountry.value;
        this.dataService.defaultCountry = this.countryDefault;
      })

      this.dataService.getObject('mobileUiText').then(mobileText => {
        this.dataService.mobileUiText = JSON.parse(mobileText.value);
        this.mobileUiText = this.dataService.mobileUiText;
        this.dataService.settings = this.mobileUiText?.id_7;
        this.dataService.downloads = this.mobileUiText?.id_81;
      })
      this.dataService.getObject('languageDropdown').then(languageDropdown => {
        this.dataService.languageDropdown = JSON.parse(languageDropdown.value);
        this.languageDropdown = this.dataService.languageDropdown;
      })
      this.dataService.getObject('countryDropdown').then(countryDropdown => {
        this.dataService.countryDropdown = JSON.parse(countryDropdown.value);
        this.countryDropdown = this.dataService.countryDropdown;
      })
    })
    this.networkStatus = this.dataService.networkStatus;
    this.productId = this.dataService.productId;
  }
  //Backbutton dismiss of Modal to setting page.
  public GoBack() {
    this.modalController.dismiss();
  }
  async presentLoading() {
    (await this.loading).present();
  }
  countryChanged(event) {

    this.countryDefault = event.detail.value;

    const country = this.countryDropdown.filter(
      item => { return item.value === event.detail.value })

    this.countryCode = country[0].id;

    this.getCountryData(event.detail.value, this.countryCode);
  }
  getCountryData(name, countryCode) {

    if (this.dataService.networkStatus) {
      this.dataService.setObject("defaultCountry", name);
      this.dataService.setObject("defaultCountryCode", countryCode);
      this.dataService.defaultCountry = name;
      this.dataService.defaultCountryId = countryCode;
       this.dataService.appInstall=true;
      this.presentLoading();
      this.dataService.countryChange(name);
    }
    else {

      this.countryDefault = this.dataService.defaultCountry;

      this.notifyController(this.mobileUiText.id_45);
    }
  }
  languageChanged(event) {
    try {
      const language = this.languageDropdown.filter(
        item => {
          return item.name === event.detail.value
        })
      this.languageDefault = event.detail.value;
      this.deviceLangCode = language[0].code;
      this.getMobileUiData();
    } catch (error) {
    }
    
  }
  getMobileUiData() {
    if (this.dataService.networkStatus) {
      this.fetchAndInsert();
       this.dataService.appInstall=true;
    }
    else {
      this.languageDefault = this.dataService.defaultLanguage;
      this.deviceLangCode = this.dataService.deviceLangCode;
      this.notifyController(this.mobileUiText.id_44);
    }
  }
  fetchAndInsert() {
    this.dataService.mobileUiTextApi(this.deviceLangCode).then((mobileText) => {

      this.dataService.setObject("mobileUiText", mobileText.data[0].text).then(data => {
        this.dataService.mobileUiText = mobileText.value;
      })
    })
    this.dataService.mobileProdListApi(this.deviceLangCode).then((apidata) => {

      this.dataService.setObject('defaultLangCode', this.deviceLangCode);
      this.dataService.setObject('defaultLanguage', this.languageDefault);
      this.dataService.defaultLanguage = this.languageDefault;
      this.dataService.deviceLangCode = this.deviceLangCode;

      this.dataService.setObject('listItem', apidata.data).then(data => {

        this.dataService.getObject('listItem').then(List => {
          this.dataService.productList = JSON.parse(List.value);
          this.presentLoading();
          this.dataService.change(this.deviceLangCode);
        })
      })
    })

    this.dataService.mobileBannerApi(this.deviceLangCode).then((apidata) => {

      this.dataService.setObject(`bannerList"${this.deviceLangCode}`, apidata.data).then(data => {

      })

    })

    this.dataService.mobileCountryApi(this.deviceLangCode).then((apidata)=>{      
      const country = apidata.data.filter(
        item => { return item.id ===  this.dataService.defaultCountryId})
      this.getCountryData(country[0].value, country[0].id);
      this.dataService.setObject(`countryDropdown`, apidata.data).then(data => {
        this.dataService.getObject('countryDropdown').then(countryDropdown => {
          this.dataService.countryDropdown = JSON.parse(countryDropdown.value);
          this.countryDropdown = this.dataService.countryDropdown;
          

          this.dataService.getObject('defaultCountry').then(defaultCountry => {
            this.countryDefault = country[0].value;
            this.dataService.defaultCountry = this.countryDefault;
          })
        })
      })

     
    })

  }
  async notifyController(uiText) {
    const alert = await this.alertController.create(
      {
        header: this.mobileUiText.id_47,
        message: uiText,
        backdropDismiss: false,
        buttons: [{
          text: this.mobileUiText.id_48,
          role: this.mobileUiText.id_48,
          handler: () => { this.GoBack() },
        }],
      });
    await alert.present();
  }
}
