import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { PopoverController, ToastController, ModalController, NavParams } from '@ionic/angular';
import { DataService } from '../services/data.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { constants } from '../../app/constants';
import { Device } from '@capacitor/device';
import { LoadingController } from '@ionic/angular';
import { MediaObserver, MediaChange } from '@angular/flex-layout';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-otp-popover',
  templateUrl: './otp-popover.component.html',
  styleUrls: ['./otp-popover.component.scss'],
})
export class OtpPopoverComponent implements OnInit {
  otpInfo:any;
  OTP: string;
  isValid: any = true;
  mobileUiText: any;
  constructor(private readonly popover: PopoverController, public modalController: ModalController,
    public dataService: DataService, public formBuilder: FormBuilder,
    public toastController: ToastController, private readonly mediaObserver: MediaObserver, public navParams:NavParams) {
  }
  ngOnInit() {
    this.otpInfo = this.navParams.get('email');
    this.dataService.getObject('mobileUiText').then(mobileText => {
      this.mobileUiText = JSON.parse(mobileText.value);

    })
  }

  onOTPChange(event) {
    if(event) {
      this.OTP = event.target.value;
      this.isValid = false;
    } else {
      this.isValid = true;
    }
  }

  OTPSend() {
    this.popover.dismiss({ otp: this.OTP });
  }

  closePopover() {
    this.popover.dismiss();
  }

}