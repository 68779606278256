import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { PopoverController, ToastController, ModalController } from '@ionic/angular';
import { DataService } from '../services/data.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { constants } from '../../app/constants';
import { Device } from '@capacitor/device';
import { LoadingController } from '@ionic/angular';
import { MediaObserver, MediaChange } from '@angular/flex-layout';
import { filter } from 'rxjs/operators';
import { OtpPopoverComponent } from '../otp-popover/otp-popover.component';
import { Capacitor } from '@capacitor/core';


@Component({
  selector: 'app-info-popover',
  templateUrl: './info-popover.component.html',
  styleUrls: ['./info-popover.component.scss'],
})
export class InfoPopoverComponent implements OnInit {
  @Input() infoPopup: any;
  @Input() industryName: any;
  @Input() id: any;
  @Input() itemid: any;
  form: FormGroup;
  defaultMailContent = true;
  Header = 'Email'
  current = 'listProducts';
  industryDetail;
  industryInfo;
  mobileUiText: any;
  isMobileUitext = false;
  mailContentObj = {
    "to_address": [], "lang_code": "", "emaildata":{},"from_address": "", "signature": "", "data": [],
  };
  SignatureStorage = { "name": "", "mobileno": "", "mailid": "", "website": "" };
  mailContent: any;
  template = '';
  isXs$;
  item: any = { isChecked: true };
  SignChangeBool: boolean = false;
  name = null;
  mobileNo = null;
  mailId = null;
  website = null;
  iosPlatform;
  Platform;
  isMobile;
  constructor(private readonly router: Router, public loadingController: LoadingController, private readonly popover: PopoverController, public modalController: ModalController,
    public dataService: DataService, public formBuilder: FormBuilder,
    public toastController: ToastController, private readonly mediaObserver: MediaObserver) {
       this.Platform =Capacitor.getPlatform();
      if((this.Platform === 'android') || (this.Platform === 'ios')) { 
        this.isMobile = true;
      }
         this.iosPlatform = Capacitor.getPlatform()==='ios';
    this.dataService.SetSignatureData().then(data => {
      if (this.dataService.SignatureStore) {
        let signStorage = this.dataService.SignatureStore;
        this.name = signStorage.name;
        this.mobileNo = signStorage.mobileno;
        this.website = signStorage.website;
        this.mailId = signStorage.mailid;
      }
      this.form.patchValue({ name: this.name })
      this.form.patchValue({ mobileNo: this.mobileNo })
      this.form.patchValue({ mailId: this.mailId })
      this.form.patchValue({ website: this.website })

    })
    this.form = this.formBuilder.group({
      //fromMail: [null, [Validators.required, Validators.pattern("^[a-z0-9 ]*$")]],
      toMail: [null, [Validators.pattern("^(([ A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z  ]{2,4}(,|$) ?))*$")]],
      name: [null, [Validators.required]],
      mobileNo: [null, [Validators.pattern("^[0-9]*$")]],
      mailId: [null, [Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z  ]{2,4}$")]],
      website: [null, []],
      termsandconditions: [null, [Validators.required]],
    });
  }
  get f() { return this.form.controls; }
  ngOnInit() {
    this.dataService.getObject('mobileUiText').then(mobileText => {
      this.dataService.mobileUiText = JSON.parse(mobileText.value);
      this.mobileUiText = JSON.parse(mobileText.value);
      this.isMobileUitext = true;
      this.Header = this.mobileUiText.id_23;
      this.dataService.settings = this.mobileUiText?.id_7;
      this.dataService.downloads = this.mobileUiText?.id_81;
      this.dataService.home = this.mobileUiText.id_33;

    })
    if (this.itemid) {
      this.dataService.getObject(this.itemid).then(details => {
        this.industryDetail = JSON.parse(details.value);
        if (this.industryDetail) {
          this.industryInfo = this.industryDetail.filter(item => {
            return item.unique_id === this.id
          }
          );
        }
      })
    }
    this.mailContent = this.dataService.mailContentArray;
    
  }

  public media$ = this.mediaObserver.asObservable().pipe(filter(
    (change: MediaChange[]) => change[0].mqAlias === 'xs')).subscribe(() => {
      this.isXs$ = true;
    });
  info(inputkey) {
    let href = this.router.url;
    if (inputkey === "Applications") {

      this.popover.dismiss();
      this.router.navigate(['home/IndustryPage'], {
        queryParams: {
          id: this.id, info: true, industryName: this.industryName,
          text: this.dataService.mobileUiText.id_6, catid: this.itemid, back: href
        }
      });
    } else {
      let href = this.router.url;
      this.popover.dismiss();
      this.router.navigate(['home/productDetails'], { queryParams: { id: this.id, info: true, title: this.industryName, catid: this.itemid, back: href } });
    }
  }

  
  OTP: string = "";
  OTPRetry: number = 1;
  async sendPopover(loading, OTPRetry, errorMessage) {
    const popover = await this.popover.create({
      component: OtpPopoverComponent,
      cssClass: 'popover_setting',
      componentProps: {
        email: this.form.get('mailId').value,
        OTPRetry:  OTPRetry,
        errorMessage: errorMessage
      },
      backdropDismiss: false,
      translucent: false
    });

    popover.onDidDismiss().then((result) => {
      if (result.data) {
        this.OTP = result.data.otp;
        this.OTPSend();
      } else {
        this.defaultMailContent = true;
      }
  
    });
    await loading.dismiss();
    return await popover.present();
  }

  async send() {
    //this.mailContentObj.from_address = this.form.get('fromMail').value;
    this.mailContentObj.to_address = this.form.get('toMail').value;
    this.mailContentObj.lang_code = this.dataService.deviceLangCode;
    const name = `<div><b>Thanks And Regards,<b><p style="margin: 0px">${this.form.get('name').value}</p>`;
    const no = (this.form.get('mobileNo').value !== null && this.form.get('mobileNo').value !== '') ? `<p style="margin: 0px">${this.form.get('mobileNo').value}</p>` : '';
    const email = (this.form.get('mailId').value !== null) ? `<p style="margin: 0px">${this.form.get('mailId').value}</p>` : '';
    const website = (this.form.get('website').value !== null && this.form.get('website').value !== '') ? `<p style="margin: 0px">${this.form.get('website').value}</p></div>` : '</div>';
    const signature = `${name}${no}${email}${website}`;
    // this.mailContentObj.signature = signature;
    this.mailContentObj.emaildata = {
      name: this.form.get('name').value,
      mobileNo: (this.form.get('mobileNo').value !== null && this.form.get('mobileNo').value !== '') ? this.form.get('mobileNo').value : '',
      mailId: (this.form.get('mailId').value !== null) ? this.form.get('mailId').value : '',
      website: (this.form.get('website').value !== null && this.form.get('website').value !== '') ? this.form.get('website').value : ''
    }
    this.mailContentObj.data = this.dataService.mailContentArray;



    if (this.form.get('mailId').value !== null) {
      const mailID = this.form.get('mailId').value;

      const loading = await this.loadingController.create({
        message: 'Please wait',
        //duration: 2000
      });
      await loading.present();
      const deviceId = await Device.getId();
      this.dataService.emailVerifyApi({ "email": mailID, "device_uid": deviceId.identifier, "lang_code":this.dataService.deviceLangCode }).then(async res => {
        
        if (res.status === true && res.is_verify === false) {
          this.defaultMailContent = false;
          this.sendPopover(loading, this.OTPRetry, res);         
          
        } else if (res.status === true && res.is_verify === true) {
          this.defaultMailContent = true;
          this.Header = "Email";
          this.dataService.mobileSendMailApi(JSON.stringify(this.mailContentObj)).then(async mailRes => {
            if (mailRes.message === 'Email sent successfully') {
              this.dataService.mailContentArray = [];
              this.dataService.mailCount = 0;
              this.dataService.setMailCount(0);
              this.discard();
              this.presentToast(true);
              await loading.dismiss();
            }
            else {
           
              const toast = await this.toastController.create({

                message: mailRes.message,
                duration: 3000,
                cssClass: constants.isNetworkOfflineCss,
    
              });
              toast.present();
              await loading.dismiss();
            }
          })
        } else if (res.status === false) {
          await loading.dismiss();          
          
          const toast = await this.toastController.create({

            message: res.message,
            duration: 2000,
            cssClass: constants.isNetworkOfflineCss,

          });
          toast.present();
        }

       
      }, async error => {
        this.defaultMailContent = true;
        await loading.dismiss();
      });

    }




  }


  async OTPSend() {

    if (this.form.get('mailId').value !== null) {
      const mailID = this.form.get('mailId').value;

      const loading = await this.loadingController.create({
        message: 'Please wait'
      });
      await loading.present();
      const deviceId = await Device.getId();
      this.dataService.emailVerifyApi({ "email": mailID, "device_uid": deviceId.identifier, "otp": this.OTP, "lang_code":this.dataService.deviceLangCode }).then(async res => {
        
        if (res.status) {
          this.dataService.mobileSendMailApi(JSON.stringify(this.mailContentObj)).then(async mailRes => {
            if (mailRes.message === 'Email sent successfully') {
              this.dataService.mailContentArray = [];
              this.dataService.mailCount = 0;
              this.dataService.setMailCount(0);
              await loading.dismiss();
              this.discard();
              this.presentToast(true);
            }
            else {
              await loading.dismiss();
          
              const toast = await this.toastController.create({

                message: mailRes.message,
                duration: 3000,
                cssClass: constants.isNetworkOfflineCss,
    
              });
              toast.present();
            }
          })
        } else {
          this.defaultMailContent = false;
          let otpmessage;
         
          if(this.OTPRetry < 3){
            this.sendPopover(loading, this.OTPRetry, this.dataService.mobileUiText.id_64); 
            otpmessage = this.dataService.mobileUiText.id_65;  
          } else {
            this.discard();
            otpmessage = this.dataService.mobileUiText.id_66;  
          }                         
          this.OTPRetry = this.OTPRetry + 1;
          await loading.dismiss();
          const toast = await this.toastController.create({

            message: otpmessage,
            duration: 5000,
            cssClass: constants.isNetworkOfflineCss,

          });
          toast.present();
        }
        

      });
    }
  }

  onCheckDisclaimer(event: any) {
    this.SignatureStorage.name = this.form.get('name').value;
    this.SignatureStorage.mobileno = this.form.get('mobileNo').value;
    this.SignatureStorage.mailid = this.form.get('mailId').value;
    this.SignatureStorage.website = this.form.get('website').value;

    if (this.dataService.SignatureStore === null) {
      let SigResponse = this.dataService.keyValSetObject("SignatureData", this.SignatureStorage);
    } else {
      this.dataService.setObject("SignatureData", this.SignatureStorage)
    }
    if (!event.target.checked) {
      this.form.get('termsandconditions').setValue(null);
    }
  }



  discard() {
    this.dataService.mailContentArray = [];
    this.dataService.mailCount = 0;
    this.dataService.setMailCount(0);
    this.modalController.dismiss();
  }
 
  signature(event) {
    if (event.value === 'Template1') {
      this.form.get('website').clearValidators();
    }
    this.template = event.value;
  }


  // Toast Notification for network status
  async presentToast(input) {
    const toast = await this.toastController.create({
      // message: network?constants.isNetworkOnline:constants.isNetworkOffline,
      message: input ? this.mobileUiText.id_49 : '',
      duration: 3000,
      cssClass: input ? constants.isNetworkOnlineCss : constants.isNetworkOfflineCss,

    });
    toast.present();
  }


  //ON Signature change in form need to update lStorage, so bool var is update
  SignatureChange() {
    this.SignChangeBool === true;
  }
}